import React, { useEffect, useState } from "react"
import styles from "./style.module.scss"
import images from "../../../core/constants/images"
import { projectsObject } from "../../../core/constants/data"
import ProjectCard from "../../components/Portofolio/ProjectCard"
import ScreenHeader from "../../components/ScreenHeader/ScreenHeader"
import Footer from "../../components/Footer/Footer"
import strings from "../../../core/constants/strings"
import ScrollUp from "../../components/ScrollUp/ScrollUp"
import OurPartnersImages from "../../components/OurPartnersImages/OurPartnersImages"
import OurWorkDetails from "../../components/OurWork/OurWorkDetails"
import ReviewCard from "../../components/ReviewCard"

const Portofolio = () => {
	const [reviewData, setReviewData] = useState<any>([])
	const getReviewsFromObject = (projects: any) => {
		const uniqueReviews: any[] = []
		const seenTexts = new Set()

		Object.values(projects).forEach((project: any) => {
			if (project.review && project.review.text && !seenTexts.has(project.review.text)) {
				seenTexts.add(project.review.text)
				uniqueReviews.push(project.review)
			}
		})

		return setReviewData(uniqueReviews)
	}

	useEffect(() => {
		getReviewsFromObject(projectsObject)
	}, [])

	return (
		<div className={styles.container}>
			<OurWorkDetails />
			<div className={styles.ourworkDetailsContainer}>
				<span className={styles.ourworkDetails}>{strings.ourwork.ourworkDetails}</span>
				<a href={`/contact/`} style={{ textDecoration: "none" }}>
					<span className={styles.contactUs}>{strings.portofolio.contactUs}</span>
				</a>
			</div>
			<div
				className={styles.mainTextContainer}
				style={{
					backgroundImage: `url(${images.portofolio.latestProjectsPath})`
				}}
			></div>
			<div className={styles.ourPartners}>
				<OurPartnersImages />
			</div>
			{Object.values(projectsObject).map((project, index) => {
				return (
					<div className={styles.projectCardContainer}>
						<ProjectCard
							title={project.projectPortofolioName}
							description={project.description}
							imagesProject={project.projectImage}
							altImages={project.projectName}
							indexCard={index}
							portofolioName={project.projectPortofolioName}
						/>
					</div>
				)
			})}
			<ReviewCard reviewData={reviewData} />
			<ScrollUp />
			<Footer />
		</div>
	)
}

export default Portofolio
