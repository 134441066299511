const images = {
	ourwork: {
		development: require("../assets/ourwork/development.svg").default,
		suitcase: require("../assets/ourwork/suitcase.svg").default,
		handshake: require("../assets/ourwork/handshake.svg").default
	},
	portofolio: {
		topbackground: require("../assets/topbackground.svg").default,
		latestProjectsPath: require("../assets/latestProjectsPath.png"),
		latestProjectsPathTablet: require("../assets/latestTablet.svg").default,

		latestProjectsPathMobile: require("../assets/latestMobile.svg").default,
		portofolioFirstPhoto: require("../assets/portofoliopage/FirstPhoto.svg").default,
		portofolioFirstTablet: require("../assets/portofoliopage/PortofolioFirstPhotoTablet.svg").default,

		reactIcon: require("../assets/reactIcon.svg").default,
		xdIcon: require("../assets/xdIcon.svg").default,
		webDevIcon: require("../assets/webDevIcon.svg").default,
		uiUXicon: require("../assets/uiUXicon.svg").default,
		dogScoutsLogo: require("../assets/dogScoutsLogo.png"),
		chalkNotesLogo: require("../assets/chalkNotesLogo.png"),
		romanticStudioLogo: require("../assets/romanticStudioLogo.png"),
		portofolio: require("../assets/portofolio.png"),
		ausy: require("../assets/portofoliopage/ausy.svg").default,
		ausyColor: require("../assets/portofoliopage/ausyColor.svg").default,
		ness: require("../assets/portofoliopage/ness.svg").default,
		nessColor: require("../assets/portofoliopage/nessColor.svg").default,
		izibac: require("../assets/portofoliopage/izibac.svg").default,
		izibacColor: require("../assets/portofoliopage/izibacColor.svg").default,
		monlet: require("../assets/portofoliopage/monlet.svg").default,
		monletColor: require("../assets/portofoliopage/monletColor.svg").default,
		odeen: require("../assets/portofoliopage/odeen.svg").default,
		odeenColor: require("../assets/portofoliopage/odeenColor.svg").default,
		pentalog: require("../assets/portofoliopage/pentalog.svg").default,
		pentalogColor: require("../assets/portofoliopage/pentalogColor.svg").default,
		romanticStudioApartment: require("../assets/portofoliopage/RomanticStudioApartment.png"),
		oasisResidence: require("../assets/portofoliopage/OasisResidence.png"),
		BSR: require("../assets/portofoliopage/BSR.png"),
		bookTrade: require("../assets/portofoliopage/BookTrade.png"),
		chalkNotes: require("../assets/portofoliopage/ChalkNotes.png"),
		dogScouts: require("../assets/portofoliopage/DogScouts.png"),
		zem: require("../assets/portofoliopage/zem.png"),
		bookTradelogo: require("../assets/portofoliopage/booktradeLogo.png"),
		BSRlogo: require("../assets/portofoliopage/BSRlogo.png"),
		chalkNoteslogo: require("../assets/portofoliopage/chalkNotesLogo.png"),
		dogScoutslogo: require("../assets/portofoliopage/dogScoutsLogo.png"),
		oasislogo: require("../assets/portofoliopage/oasisLogo.png"),
		zemlogo: require("../assets/portofoliopage/zemLogo.png"),
		romanticStudiologo: require("../assets/portofoliopage/romanticStudioLogo.png"),
		dentalike: require("../assets/portofoliopage/DentaLike.png"),
		mindHub: require("../assets/portofoliopage/MindHub.png"),
		nestInn: require("../assets/portofoliopage/NestInn.png"),
		aquAngar: require("../assets/portofoliopage/AquAngar.png"),
		maxStoryOutlet: require("../assets/portofoliopage/MaxStoryOutlet.png"),
		dentaLikeLogo: require("../assets/portofoliopage/DentaLikeLogo.png"),
		// aquAngar: require("../assets/portofoliopage/AquAngar.png"),
		// maxStoryOutlet: require("../assets/portofoliopage/MaxStoryOutlet.png"),
		reviewBackground: require("../assets/portofoliopage/ReviewBackground.svg").default,
		reviewBackgroundTablet: require("../assets/portofoliopage/ReviewBackgroundTablet.svg").default,
		projectBannerTablet: require("../assets/portofoliopage/ProjectBannerTablet.svg").default,
		reviewBackgroundMobile: require("../assets/portofoliopage/ReviewBackgroundMobile.svg").default,
		casaCerbului: require("../assets/portofoliopage/CasaCerbului.png"),
		reviewCardLeftArrow: require("../assets/reviewCardLeftArrow.svg").default,
		reviewCardRightArrow: require("../assets/reviewCardRightArrow.svg").default
	},

	projectScreens: {
		bookTrade: [
			require("../assets/projectScreens/booktrade_1.png"),
			require("../assets/projectScreens/booktrade_2.png"),
			require("../assets/projectScreens/booktrade_3.png"),
			require("../assets/projectScreens/booktrade_4.png")
		],
		brasov: [
			require("../assets/projectScreens/brasov_1.png"),
			require("../assets/projectScreens/brasov_2.png"),
			require("../assets/projectScreens/brasov_3.png"),
			require("../assets/projectScreens/brasov_4.png")
		],
		oasis: [
			require("../assets/projectScreens/oasis1.png"),
			require("../assets/projectScreens/oasis2.png"),
			require("../assets/projectScreens/oasis3.png"),
			require("../assets/projectScreens/oasis4.png")
		],
		zem: [require("../assets/projectScreens/zem1.png"), require("../assets/projectScreens/zem2.png")],
		bsr: [
			require("../assets/projectScreens/bsr1.png"),
			require("../assets/projectScreens/bsr2.png"),
			require("../assets/projectScreens/bsr3.png"),
			require("../assets/projectScreens/bsr4.png")
		],
		chalknotes: [
			require("../assets/projectScreens/chalknotes1.png"),
			require("../assets/projectScreens/chalknotes2.png"),
			require("../assets/projectScreens/chalknotes3.png")
		],
		dogScouts: [require("../assets/projectScreens/dogscouts1.png")]
	},

	aboutUs: {
		mainPhoto: require("../assets/aboutus/PozaPrincipala.jpg"),
		teamwork: require("../assets/aboutus/Teamwork.jpg"),
		transparency: require("../assets/aboutus/Transparency.JPG"),
		empoweringCommunities: require("../assets/aboutus/EmpoweringCommunities.jpg"),
		equity: require("../assets/aboutus/Equity.jpg"),
		friendship: require("../assets/aboutus/Friendship.jpeg"),
		growth: require("../assets/aboutus/Growth.jpg"),
		bundleBackground: require("../assets/aboutus/ImageBundleBackground.png")
	},
	arrowRight: require("../assets/profile/arrow_right.svg").default,
	arrowLeft: require("../assets/profile/arrow_left.svg").default,
	orangeCheckBox: require("../assets/profile/orange_checkbox_icon.svg").default,
	dropdownArrow: require("../assets/profile/dropdown_arrow.svg").default,
	logo: require("../assets/logo.svg").default,
	headerTableta: require("../assets/headerTableta.svg").default,
	header: require("../assets/header.svg").default,
	headerMobile: require("../assets/headerMobile.svg").default,
	services: require("../assets/services.png"),
	services_nmcp: require("../assets/services_nmcp.svg").default,
	services_nmcp_mobile: require("../assets/services_nmcp_mobile.png"),
	services_oasis: require("../assets/services_oasis.png"),
	app_development: require("../assets/app_development.svg").default,
	web_development: require("../assets/web_development.svg").default,
	ui_ux: require("../assets/ui-ux.svg").default,
	marketing: require("../assets/marketing.svg").default,
	services_romantic: require("../assets/services_romantic.png"),
	services_chalknotes: require("../assets/services_chalknotes.png"),
	facebook: require("../assets/facebook.svg").default,
	instagram: require("../assets/instagram.svg").default,
	linkedin: require("../assets/linkedin.svg").default,
	mail: require("../assets/mail.svg").default,
	phone: require("../assets/phone.svg").default,
	location: require("../assets/location.svg").default,
	profile_background: require("../assets/ProfileBackground.svg").default,
	profile_background_mobile: require("../assets/ProfileBackgroundMobile.svg").default,
	team_members: require("../assets/TeamMembers.svg").default,
	ourTeam: require("../assets/ourTeam.png"),
	contactUs: require("../assets/contactUs.png"),
	nmcpCommunity: require("../assets/nmcpCommunity.svg").default,
	discord: require("../assets/discord.svg").default,
	discordHover: require("../assets/discordHover.svg").default,
	map: require("../assets/map.png"),
	projectBannerDesktop: require("../assets/projectBannerDekstop.svg").default,
	ourTeamBannerMobilePrjectTm: require("../assets/ourTeamBannerMobileProjectTm.svg").default,
	graficaTehnologii: require("../assets/homepage/graficaTehnologii.png"),
	whyUs: require("../assets/homepage/whyUs.png"),
	banner: require("../assets/homepage/banner.svg").default,
	banner2: require("../assets/homepage/banner3.svg").default,
	letterA: require("../assets/homepage/Litera A.svg").default,
	letterM: require("../assets/homepage/Litera M.svg").default,
	letterU: require("../assets/homepage/Litera U.svg").default,
	letterW: require("../assets/homepage/Litera W.svg").default,
	servicesBanner: require("../assets/homepage/servicesBanner.svg").default,
	servicesBannerMobile: require("../assets/homepage/servicesBannerMobile.svg"),
	servicesBannerTablet: require("../assets/homepage/servicesBannerTablet.svg").default,
	mobileDev: require("../assets/homepage/mobileDev.svg").default,
	webDev: require("../assets/homepage/webDev.svg").default,
	marketingHome: require("../assets/homepage/marketingHome.svg").default,
	uiUX: require("../assets/homepage/uiUX.svg").default,
	portofolioLogo: require("../assets/homepage/portofolioLogo.png"),
	dogScoutsLogo: require("../assets/dogScoutsLogo.png"),
	chalkNotesLogo: require("../assets/chalkNotesLogo.png"),
	romanticStudioLogo: require("../assets/romanticStudioLogo.png"),
	zemLogo: require("../assets/homepage/zemLogo.png"),
	izibacLogo: require("../assets/homepage/izibacLogo.png"),
	bookTradeLogo: require("../assets/homepage/bookTradeLogo.png"),
	oasisLogo: require("../assets/homepage/oasisLogo.png"),
	bsrLogo: require("../assets/homepage/bsrLogo.png"),
	portofolioBanner: require("../assets/homepage/portofolioBanner.svg").default,
	portofolioBannerMobile: require("../assets/homepage/portofolioBannerMobile.svg").default,
	portofolioBannerTablet: require("../assets/homepage/portofolioBannerTablet.svg").default,

	whattoexpect: require("../assets/homepage/whattoexpect.svg").default,
	expectLine: require("../assets/homepage/expectLine.svg").default,
	expectLineMobile: require("../assets/homepage/expectLineMobile.svg").default,
	brainstorming: require("../assets/homepage/brainstorming.svg").default,
	settingsTools: require("../assets/homepage/settingsTools.svg").default,
	executionTools: require("../assets/homepage/executionTools.png"),
	emailTools: require("../assets/homepage/emailTools.png"),
	ourTeamLogo: require("../assets/homepage/ourTeam.svg").default,
	ourTeamBanner: require("../assets/homepage/ourTeamBanner.svg").default,
	ourTeamBannerTabletProject: require("../assets/ourTeamBannerTabletProject.svg").default,
	ourTeamBannerTabletProjectRv: require("../assets/ourTeamBannerTabletProjectRv.svg").default,
	ourTeamBannerMobile: require("../assets/homepage/ourTeamBannerMobile.svg").default,
	ourTeamBannerTablet: require("../assets/homepage/ourTeamBannerTablet.svg").default,
	ourTeamBannerMobilePNG: require("../assets/homepage/ourTeamBannerMobilePNG.png").default,
	Stoica: require("../assets/homepage/Stoica.png"),
	Andreea: require("../assets/homepage/Andreea.png"),
	Norbi: require("../assets/homepage/Norbi.png"),
	Denis: require("../assets/homepage/Denis.png"),
	Adi: require("../assets/homepage/Adi.png"),
	AncaPop: require("../assets/homepage/AncaPop.png"),
	AndreiPop: require("../assets/homepage/AndreiPop.png"),
	Catalin: require("../assets/homepage/Catalin.png"),
	Dani: require("../assets/homepage/Dani.png"),
	DianaDunca: require("../assets/homepage/DianaDunca.png"),
	Kinga: require("../assets/homepage/Kinga.png"),
	Marcel: require("../assets/homepage/Marcel.png"),
	PaulTraian: require("../assets/homepage/PaulTraian.png"),
	Sylvi: require("../assets/homepage/Sylvi.png"),
	Rowena: require("../assets/homepage/Rowena.png"),
	BogdanPop: require("../assets/homepage/BogdanPop.png"),
	StoicaArt: require("../assets/homepage/StoicaArt.png"),
	AndreeaArt: require("../assets/homepage/AndreeaArt.png"),
	NorbiArt: require("../assets/homepage/NorbiArt.png"),
	DenisArt: require("../assets/homepage/DenisArt.png"),
	AdiArt: require("../assets/homepage/AdiArt.png"),
	AncaPopArt: require("../assets/homepage/AncaPopArt.png"),
	AndreiPopArt: require("../assets/homepage/AndreiPopArt.png"),
	CatalinArt: require("../assets/homepage/CatalinArt.png"),
	DaniArt: require("../assets/homepage/DaniArt.png"),
	DanielLaiosArt: require("../assets/homepage/DanielLaiosArt.png"),
	AndraElekesArt: require("../assets/homepage/AndraElekesArt.png"),
	BogdanTintaArt: require("../assets/homepage/BogdanTintaArt.png"),
	AlexVitalariuArt: require("../assets/homepage/AlexVitalariuArt.png"),
	NicoStoicaArt: require("../assets/homepage/NicoStoicaArt.png"),
	DanielLaios: require("../assets/homepage/DanielLaios.png"),
	AndraElekes: require("../assets/homepage/AndraElekes.png"),
	BogdanTinta: require("../assets/homepage/BogdanTinta.png"),
	AlexVitalariu: require("../assets/homepage/AlexVitalariu.png"),
	NicoStoica: require("../assets/homepage/NicoStoica.png"),
	KingaArt: require("../assets/homepage/KingaArt.png"),
	MarcelArt: require("../assets/homepage/MarcelArt.png"),
	PaulTraianArt: require("../assets/homepage/PaulTraianArt.png"),
	SylviArt: require("../assets/homepage/SylviArt.png"),
	TudorEugeniu: require("../assets/homepage/TudorEugeniu.png"),
	TudorEugeniuArt: require("../assets/homepage/TudorEugeniuArt.png"),
	BiancaBaciu: require("../assets/homepage/BiancaBaciu.png"),
	lineBold: require("../assets/homepage/lineBold.svg").default,
	lineNotBold: require("../assets/homepage/lineNotBold.svg").default,
	team_membersTablet: require("../assets/TeamMembersTableta.svg").default,
	profilePic: require("../assets/Profile.png"),
	profilePicHover: require("../assets/ProfileHover.png"),
	blueBannerMobile: require("../assets/homepage/blueBannerMobile.svg").default,
	orangeBannerMobile: require("../assets/homepage/orangeBannerMobile.svg").default,
	topHomepageBannerMobile: require("../assets/homepage/topHomepageBannerMobile.svg").default,
	topHomepageBannerTablet: require("../assets/homepage/topHomepageBannerTablet.svg").default,
	servicesCirclesLinesMobile: require("../assets/homepage/servicesCirclesLinesMobile.svg").default,
	servicesCirclesLinesTablet: require("../assets/homepage/servicesCirclesLinesTablet.svg").default,
	portofolioCirclesLinesMobile: require("../assets/homepage/portofolioCirclesLinesMobile.svg").default,
	expectCirclesLinesMobile: require("../assets/homepage/portofolioCirclesLinesMobile.svg").default,
	expectCirclesLinesTablet: require("../assets/homepage/expectCirclesLinesTablet.svg").default,
	nmcpLogo: require("../assets/homepage/nmcpLogo.svg").default,
	nmcpLogoTablet: require("../assets/homepage/nmcpLogoTablet.svg").default,
	blog: require("../assets/blog.png"),
	webDevBlog: require("../assets/webDevBlog.png"),
	marketingBlog: require("../assets/marketingBlog.png"),
	designBlog: require("../assets/designBlog.png"),
	blogPostBackground: require("../assets/blogPostBackground.png"),
	upButtonArrow: require("../assets/upButtonArrow.svg").default,
	upButtonArrowWhite: require("../assets/upButtonArrowWhite.svg").default,
	eventsBar: require("../assets/eventsBar.png"),
	circleLinesEvents: require("../assets/circleLinesEvents.svg").default,
	whiteRectangle: require("../assets/whiteRectangle.svg").default,
	locationIcon: require("../assets/locationIcon.svg").default,
	watchIcon: require("../assets/watchIcon.svg").default,
	nmcpLogoEvents: require("../assets/nmcpLogoEvents.svg").default,
	circleLinesEventsMobile: require("../assets/circleLinesEventsMobile.svg").default,
	circleLinesEventsTablet: require("../assets/circleLinesEventsMobile.svg").default,
	smiley: require("../assets/smiley.svg").default,
	unsmiley: require("../assets/unsmiley.svg").default,
	festivalPoster: require("../assets/festivalNmcpPoster.jpg"),
	backgroundInProgress: require("../assets/backgroundInProgress.svg").default,
	imageInProgress: require("../assets/imageInProgress.png"),
	mobilePhone: require("../assets/homepage/mobilePhone.svg").default,
	desktop: require("../assets/homepage/desktop.svg").default,
	designModel: require("../assets/homepage/designModel.svg").default,
	rocket: require("../assets/homepage/rocket.svg").default,
	blogSoon: require("../assets/blogSoon.svg").default,
	ourTeamBannerMobileSVG: require("../assets/homepage/ourTeamBannerMobileSVG.svg").default,
	casaCerbuluiLogo: require("../assets/casaCerbuluiLogo.png"),
	nestInnLogo: require("../assets/nestInnLogo.png"),
	mindhubLogo: require("../assets/mindhubLogo.png"),
	aquAngarLogo: require("../assets/AquAngarLogo.png"),
	maxStoryOutletLogo: require("../assets/MaxStoryOutletLogo.png"),
	ourTeamPhoto: require("../assets/ourTeamPhoto.JPG")
}

export default images
