import React from "react"

import styles from "./style.module.scss"
import { ourworkDetailsArray } from "../../../core/constants/arrays"

const OurWorkDetails = () => {
	return (
		<div className={styles.container}>
			{ourworkDetailsArray.map((item) => {
				return (
					<div className={styles.squareContainer} style={{ borderColor: item.color }}>
						<img className={styles.icon} src={item.icon} />
						<div className={styles.bottomContainer}>
							<span className={styles.value} style={{ color: item.color }}>
								{item.value}
							</span>
							<span className={styles.title} style={{ color: item.color }}>
								{item.title}
							</span>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default OurWorkDetails
